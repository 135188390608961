import React, {useRef, useState} from "react";
import './contact.styles.scss';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2'
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

const Contact = () => {
    const [token, setToken] = useState("");
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm(process.env.REACT_APP_MAIL_SERVICE_ID, process.env.REACT_APP_MAIL_TEMPLATE_ID, form.current, process.env.REACT_APP_MAIL_PUBKEY)
            .then(() => {
                Swal.fire({
                    title: 'Sukces',
                    text: 'Wiadomość została wysłana',
                    icon: 'success',
                    confirmButtonText: 'OK'
                }).then(e.target.reset());
            }, (error) => {
                console.log(error.text);
            });
    };
    return (
        <>
            <section id="contact" className="my-36 ">
                <div className="flex flex-col items-center justify-center lg:mx-24 mx-6">
                    <p className="text-lg font-bold text-orange-500">
                        Kontakt
                    </p>
                    <h2 className="mx-auto text-center px-4 text-4xl font-medium">
                        Skontaktuj się z nami
                    </h2>
                    <div className="flex flex-col lg:flex-row mt-12 gap-x-10 lg:w-3/5 w-full">
                        <div className="w-full">
                            <div className="p-10">
                                <form ref={form} onSubmit={sendEmail}>
                                    <GoogleReCaptcha onVerify={newToken => {
                                        if (token === "")
                                            setToken(newToken)
                                    }} />
                                    <div>
                                        <span className="uppercase text-sm text-gray-600 font-bold">Imię i nazwisko</span>
                                        <input
                                            name="user_name"
                                            id="user_name"
                                            className="w-full bg-gray-200 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                            type="text" placeholder="" required />
                                    </div>
                                    <div className="mt-8">
                                        <span className="uppercase text-sm text-gray-600 font-bold">Email</span>
                                        <input
                                            name="user_email"
                                            id="user_email"
                                            className="w-full bg-gray-200 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                            type="email" required />
                                    </div>
                                    <div className="mt-8">
                                        <span className="uppercase text-sm text-gray-600 font-bold">Wiadomość</span>
                                        <textarea
                                            name="message"
                                            id="message"
                                            className="w-full h-32 bg-gray-200 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline" required />
                                    </div>
                                    <input hidden name="g-recaptcha-response" value={token} readOnly />
                                    <div className="mt-8 flex flex-col lg:flex-row space-y-5">
                                        <button
                                            type="submit"
                                            className="uppercase text-sm font-bold tracking-wide bg-orange-500 text-white p-3 rounded-lg px-5 focus:outline-none focus:shadow-outline">
                                            Wyślij wiadomość
                                        </button>
                                        <a
                                            href="https://discord.gg/37NXPX7tdc"
                                            className="lg:ml-10 ml-0 uppercase text-sm font-bold tracking-wide bg-indigo-500 text-white p-3 rounded-lg px-5 focus:outline-none focus:shadow-outline">
                                            Dołącz do naszego discorda
                                        </a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Contact;
