import React, {useState} from "react";
import './faq.styles.scss';

const FAQ = () => {
    const [questions, setQuestions ] = useState(
        [
            {
                question: "Czy pluginy posiadają licencje?",
                answer: "Tak, pluginy są zabezpieczone i wymagają klucza licencyjnego.",
                open: false
            },
            {
                question: "Jak zdobyć klucz licencyjny?",
                answer: "Jeśli zakupiłeś plugin na stronie mc-market musisz zwrócić się po odbiór klucza do _arturek.",
                open: false
            },
            {
                question: "Czy mogę dołączyć do zespołu mineCodes?",
                answer: "Wszystkie oferty znajdują się pod zakładką Praca. Zachęchamy również do kontaktu " +
                    "w przypadku braku dostępnych ofert",
                open: false
            }
        ]
    );
    return (
        <>
            <section id="faq" className="my-36 mx-12">
                <div className="flex flex-col items-center justify-center">
                    <p className="text-lg font-bold text-orange-500">
                        F.A.Q
                    </p>
                    <h2 className="mx-auto text-center px-4 text-4xl font-medium">Często zadawane pytania</h2>
                </div>
                <dl className="mt-8 mx-auto max-w-screen-sm lg:max-w-screen-lg flex flex-col lg:flex-row lg:flex-wrap gap-y-5">
                    {questions.map((question, index) => (
                        <div className="lg:w-1/2" key={index}>
                            <div className="select-none cursor-pointer border-2 border-orange-500 mx-8 my-3 px-6 py-4 rounded-lg text-sm">
                                <dt>
                                    <div className="flex justify-between">
                                        <div className="text-xl font-semibold">
                                            {question.question}
                                        </div>
                                        <div>
                                            <i
                                                className={
                                                    "bx text-2xl" + (question.open ? " bx-chevron-up" : " bx-chevron-down")
                                                }
                                                aria-hidden="true"
                                                onClick={() => {
                                                    setQuestions(questions.map((q, i) => {
                                                        if (i === index) {
                                                            return {
                                                                ...q,
                                                                open: !q.open
                                                            }
                                                        }
                                                        return q;
                                                    }))
                                                }}
                                            />
                                        </div>
                                    </div>
                                </dt>
                                <dd className={
                                    "text-lg mt-2 leading-snug text-gray-700 " + (question.open ? "" : "hidden")
                                }
                                >
                                    {question.answer}
                                </dd>
                            </div>
                        </div>
                    ))}
                </dl>
            </section>
        </>
    )
}

export default FAQ;
