import React from 'react';
import "./navbar.styles.scss";
import 'animate.css/animate.css';
//import i18n from "i18next";

const Navbar = () => {
    const [navbarOpen, setNavbarOpen] = React.useState(false);
    return (
        <>
            <nav className="relative flex flex-wrap items-center justify-between px-2 py-3 bg-transparent mb-3">
                <div className="container flex flex-wrap justify-between items-center mx-auto">
                    <div className="flex items-center justify-around lg:w-auto w-full">
                        <a
                            className="text-3xl font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap text-orange-500"
                            href="/"
                        >
                            mineCodes
                        </a>
                        <button
                            className="text-black cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
                            type="button"
                            onClick={() => setNavbarOpen(!navbarOpen)}
                        >
                            <i className={"bx bx-menu " + (navbarOpen ? "animate__animated animate__fadeOutUp" : "")}/>
                        </button>
                    </div>
                    <div className="lg:flex lg:order-2 hidden">
                        <a href="/#contact">
                            <button className="text-white bg-[#FF9119] hover:bg-[#FF9119]/80 focus:ring-4 focus:outline-none focus:ring-[#FF9119]/50 font-medium rounded-lg text-lg text-center inline-flex items-center dark:hover:bg-[#FF9119]/80 dark:focus:ring-[#FF9119]/40">
                                <p className="py-1 px-5">
                                    <i className="bx bx-cart"/> Zamów
                                </p>
                            </button>
                        </a>
                    </div>
                    <div
                        className={
                            "justify-between items-center w-full lg:flex lg:w-auto lg:order-1 " +
                            (navbarOpen ? "fixed top-0 left-0 h-full bg-white z-50 animate__animated animate__fadeInRight overflow-hidden" : " hidden")
                        }
                    >
                        <button className="fixed lg:hidden justify-start text-right w-full z-50 text-black cursor-pointer text-xl leading-none outline-none focus:outline-none" type="button" onClick={() => setNavbarOpen(!navbarOpen)}>
                            <i className="bx bx-x mr-16 mt-8" />
                        </button>
                        <ul className={
                            "flex flex-col lg:flex-row list-none " +
                            (navbarOpen ? "justify-center items-center h-screen" : "")
                            }
                        >
                            <li className="nav-item">
                                <a
                                    className="px-3 py-2 flex items-center lg:text-lg text-2xl font-semibold leading-snug text-black hover:opacity-75"
                                    href="/"
                                    onClick={() => setNavbarOpen(false)}
                                >
                                    Strona główna
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="px-3 py-2 flex items-center lg:text-lg text-2xl font-semibold leading-snug text-black hover:opacity-75"
                                    href="/#about-us"
                                    onClick={() => setNavbarOpen(false)}
                                >
                                    O nas
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="px-3 py-2 flex items-center lg:text-lg text-2xl font-semibold leading-snug text-black hover:opacity-75"
                                    href="/#services"
                                    onClick={() => setNavbarOpen(false)}
                                >
                                    Usługi
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="px-3 py-2 flex items-center lg:text-lg text-2xl font-semibold leading-snug text-black hover:opacity-75"
                                    href="/#faq"
                                    onClick={() => setNavbarOpen(false)}
                                >
                                    FAQ
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="px-3 py-2 flex items-center lg:text-lg text-2xl font-semibold leading-snug text-black hover:opacity-75"
                                    href="/#opinions"
                                    onClick={() => setNavbarOpen(false)}
                                >
                                    Opinie
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="px-3 py-2 flex items-center lg:text-lg text-2xl font-semibold leading-snug text-black hover:opacity-75"
                                    href="/#team"
                                    onClick={() => setNavbarOpen(false)}
                                >
                                    Team
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="px-3 py-2 flex items-center lg:text-lg text-2xl font-semibold leading-snug text-black hover:opacity-75"
                                    href="/#contact"
                                    onClick={() => setNavbarOpen(false)}
                                >
                                    Kontakt
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="px-3 py-2 flex items-center lg:text-lg text-2xl font-semibold leading-snug text-black hover:opacity-75"
                                    href="/"
                                    onClick={() => setNavbarOpen(false)}
                                >
                                    Praca
                                </a>
                            </li>
                            <li className="nav-item lg:hidden flex">
                                <a
                                    className="px-3 py-2 flex items-center lg:text-lg text-2xl font-semibold leading-snug text-orange-500 hover:opacity-75"
                                    href="/#contact"
                                    onClick={() => setNavbarOpen(false)}
                                >
                                    Zamów
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Navbar;
