import React from 'react';
import "./home.styles.scss";
import Hero from "../../components/hero/hero.component";
import About from "../../components/about/about.component";
import Services from "../../components/services/services.component";
import FAQ from "../../components/faq/faq.component";
import Opinions from "../../components/opinions/opinions.component";
import Team from "../../components/team/team.component";
import Contact from "../../components/contact/contact.component";
import Footer from "../../components/footer/footer.component";

const Home = () => {
  return (
      <>
          <Hero />
          <About />
          <Services />
          <FAQ />
          <Opinions />
          <Team />
          <Contact />
          <Footer />
      </>
  );
};

export default Home;
