import React from "react";
import './footer.styles.scss';

const Footer = () => {
    return (
        <>
            <footer className="text-gray mt-36 bottom-0">
                <div
                    className="
                              container
                              flex flex-col flex-wrap
                              py-10
                              mx-auto
                              md:items-center
                              lg:items-start
                              md:flex-row md:flex-nowrap
                            "
                >
                    <div
                        className="flex-shrink-0 w-64 mx-auto text-center md:mx-0 md:text-left"
                    >
                        <span
                            className="
                                      flex
                                      items-center
                                      justify-center
                                      text-4xl
                                      font-bold
                                      text-orange-500
                                      md:justify-start
                                      text-blue
                                    "
                        >
                            mineCodes
                        </span>
                        <p className="mt-2 text-sm text-gray-500">
                            Jesteśmy zgranym zespołem, który oferuje wykonanie stron internetowych oraz projektów.
                            Dzięki wieloletniemu doświadczeniu i pracy z klientami każdy projekt jest dopracowany w 100%
                        </p>
                    </div>
                    <div className="justify-between w-full mt-4 text-center lg:flex">
                        <div className="w-full px-4 lg:w-1/3 md:w-1/2">
                            <h2 className="mb-2 font-bold tracking-widest text-gray-900">
                                Przydatne linki
                            </h2>
                            <ul className="mb-8 space-y-2 text-sm list-none">
                                <li>
                                    <a className="text-gray-600 hover:text-gray-800" href="/">Regulamin</a>
                                </li>
                                <li>
                                    <a className="text-gray-600 hover:text-gray-800" href="/">Polityka prywatności</a>
                                </li>
                            </ul>
                        </div>
                        <div className="w-full px-4 lg:w-1/3 md:w-1/2">
                            <h2 className="mb-2 font-bold tracking-widest text-gray-900">
                                Potrzebujesz pomocy?
                            </h2>
                            <ul className="mb-8 space-y-2 text-sm list-none">
                                <li>
                                    <a className="text-gray-600 hover:text-gray-800" href="/#contact">Kontakt</a>
                                </li>
                                <li>
                                    <a className="text-gray-600 hover:text-gray-800" href="https://docs.minecodes.pl/">Dokumentacja</a>
                                </li>
                                <li>
                                    <a className="text-gray-600 hover:text-gray-800" href="https://discord.gg/37NXPX7tdc">Discord</a>
                                </li>
                                <li>
                                    <a className="text-gray-600 hover:text-gray-800" href="mailto:kontakt@minecodes.pl">Mail</a>
                                </li>
                            </ul>
                        </div>
                        <div className="w-full px-4 lg:w-1/3 md:w-1/2">
                            <h2 className="mb-2 font-bold tracking-widest text-gray-900">
                                Projekty
                            </h2>
                            <ul className="mb-8 space-y-2 text-sm list-none">
                                <li>
                                    <a className="text-gray-600 hover:text-gray-800" href="https://www.mc-market.org/resources/19680/">mineLogin</a>
                                </li>
                                <li>
                                    <a className="text-gray-600 hover:text-gray-800" href="https://www.mc-market.org/resources/21646/">minePlots</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="border-t border-stone-300 lg:mx-44 mx-16 my-4"/>
                <div className="flex lg:justify-between lg:flex-row flex-col text-center pb-2 lg:mx-44">
                    <p className="text-base text-gray-400">
                        © 2022 Copyright: <span className="text-orange-500">mineCodes</span>
                    </p>
                    <p className="text-base text-gray-400">
                        Code and Design by: <a className="text-orange-500" href="https://szczurowsky.pl">Szczurowsky</a>
                    </p>
                </div>
            </footer>
        </>
    );
}

export default Footer;
