import React from "react";
import './team.styles.scss';

const Team = () => {
    return (
        <>
        <section id="team" className="my-36 mx-12">
            <div className="flex flex-col items-center justify-center">
                <p className="text-lg font-bold text-orange-500">
                    Zespół
                </p>
                <h2 className="mx-auto text-center px-4 text-4xl font-medium">Poznaj nasz zespół</h2>
                <div className="grid lg:grid-cols-4 grid-cols-1 my-12 gap-y-10 gap-x-10 cursor-default">

                    <div className="shadow-orange-300 shadow-md w-full h-full rounded-x flex flex-col justify-center items-center">
                        <img src="https://avatars.githubusercontent.com/u/61193738?v=4" alt="Avatar" className="h-fit w-3/5 rounded-full lg:mx-24 mx-6 mt-12" />
                        <div className="flex flex-row justify-around mt-6 mb-12">
                            <div className="font-medium mx-6">
                                <p className="text-2xl">Artur Kołecki</p>
                                <p className="text-orange-500">CEO and Developer</p>
                            </div>
                            <div className="text-3xl mt-4 lg:ml-12 ml-0 mr-6">
                                <a href="https://www.linkedin.com/in/artur-ko%C5%82ecki-a619191b5/">
                                    <i className="bx bxl-linkedin-square text-blue-700" />
                                </a>
                                <a href="https://github.com/arthurr0">
                                    <i className="bx bxl-github text-black" />
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="shadow-orange-300 shadow-md w-full h-full rounded-xl flex flex-col justify-center items-center">
                        <img src="https://avatars.githubusercontent.com/u/54811778?v=4" alt="Avatar" className="h-fit w-3/5 rounded-full lg:mx-24 mx-6 mt-12" />
                        <div className="flex flex-row justify-around mt-6 mb-12">
                            <div className="font-medium mx-6">
                                <p className="text-2xl">Mikołaj</p>
                                <p className="text-orange-500">Team Developer</p>
                            </div>
                            <div className="text-3xl mt-4 lg:ml-12 ml-0 mr-6">
                                <a href="https://github.com/JorgiTV">
                                    <i className="bx bxl-github text-black" />
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="shadow-orange-300 shadow-md w-full h-full rounded-xl flex flex-col justify-center items-center">
                        <img src="https://d-art.ppstatic.pl/kadry/k/r/1/b2/5f/5388b00d0ae1a_o_original.jpg" alt="Avatar" className="h-fit w-3/5 rounded-full lg:mx-24 mx-6 mt-12" />
                        <div className="flex flex-row justify-around mt-6 mb-12">
                            <div className="font-medium mx-6">
                                <p className="text-2xl">Michał Drożdż</p>
                                <p className="text-orange-500">Team Developer</p>
                            </div>
                            <div className="text-3xl mt-4 lg:ml-12 ml-0 mr-6">
                                <a href="https://github.com/kerpsondev">
                                    <i className="bx bxl-github text-black" />
                                </a>
                            </div>
                        </div>
                    </div>

                     <div className="shadow-orange-300 shadow-md w-full h-full rounded-xl flex flex-col justify-center items-center">
                        <img src="https://avatars.githubusercontent.com/u/65555461?v=4" alt="Avatar" className="h-fit w-3/5 rounded-full lg:mx-24 mx-6 mt-12" />
                        <div className="flex flex-row justify-around mt-6 mb-12">
                            <div className="font-medium mx-6">
                                <p className="text-2xl">Michał</p>
                                <p className="text-orange-500">Team Developer</p>
                            </div>
                            <div className="text-3xl mt-4 lg:ml-12 ml-0 mr-6">
                                <a href="https://github.com/Velshh">
                                    <i className="bx bxl-github text-black" />
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
        </>
    );
}

export default Team;
