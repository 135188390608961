import React from "react";
import './opinion.styles.scss';
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import Avatar from "../../static/avatar.svg"

const Opinions = () => {
    const opinions = [
        {
            stars: 5,
            text: "the best plugin I've ever seen. everything has been done by the authors good, plugin have little of a bugs, which are fixed all the time by the authors! plugin have a lot of options who need all minecraft servers owners! i recommend this to all people who want get secured login",
            author: "Dogel",
            avatar: "https://static.mc-market.org/avatars/s/312/312285.jpg?1647460665",
            product: "mineLogin"
        },
        {
            stars: 5,
            text: "best login plugin i have seen, i had problems with config but support is very quick, helpful and friendly. +rep for developers",
            author: "Xilix",
            avatar: "https://static.mc-market.org/avatars/s/349/349924.jpg?1645864161",
            product: "mineLogin"
        },
        {
            stars: 5,
            text: "Nieprawdopodobnie doskonały plugin, rozbudowany i dopracowany.\n" +
                "Bardzo szybki i kompetentny support który rozszerzył działania ponad plugin, aby dogłębnie zdiagnozować mój problem! POLECAM!",
            author: "TerribleDeath85",
            avatar: "https://static.mc-market.org/avatars/s/216/216480.jpg?1585553161",
            product: "mineLogin"
        },
        {
            stars: 5,
            text: "Na serio dobry plugin na dzialki",
            author: "pz_Gold",
            avatar: "https://static.mc-market.org/avatars/s/292/292238.jpg?1641638750",
            product: "minePlots"
        },
        {
            stars: 5,
            text: "The best plot plugin! It has a lot of useful functions for players and admins. It is definitely the best choice if you want to create a Survival + Cuboids server. On a scale from 1 to 10 I can honestly rate this plugin as 9/10.",
            author: "AW_meister",
            avatar: "",
            product: "minePlots"
        },
    ];
    return (
        <>
            <section id="opinions" className="my-36 mx-12">
                <div className="flex flex-col items-center justify-center">
                    <p className="text-lg font-bold text-orange-500">
                        Opinie
                    </p>
                    <h2 className="mx-auto text-center px-4 text-4xl font-medium">
                        Poznaj opinie naszych klienów
                    </h2>
                    <div className="my-12 w-full items-stretch flex">
                        <Swiper
                            centeredSlides={true}
                            loop={true}
                            infinite="true"
                            autoplay={true}
                            breakpoints={
                                {
                                    768: {
                                        slidesPerView: 1,
                                        spaceBetween: 50
                                    },
                                    1024: {
                                        slidesPerView: 2,
                                        spaceBetween: 50
                                    },
                                    1440: {
                                        slidesPerView: 3,
                                        spaceBetween: 100
                                    }
                                }
                            }
                        >
                            {opinions.map((opinion, index) => (
                                <SwiperSlide key={index}>
                                    <div className="h-full border-2 border-orange-500 flex flex-col items-center justify-center rounded-2xl shadow-md">
                                        <div className="text-3xl text-yellow-400 mt-16 mb-7">
                                            {Array.from({ length: opinion.stars }, (_, i) => <i className="bx bxs-star" key={i} />)}
                                            {Array.from({ length: 5 - opinion.stars }, (_, i) => <i className="bx bx-star" key={i} />)}
                                        </div>
                                        <p className="mx-12 break-words text-center">
                                            {opinion.text}
                                        </p>
                                        <div className="my-6 text-center">
                                            <img src={opinion.avatar === "" ? Avatar : opinion.avatar} alt="Avatar" className="h-24 w-24 rounded-full" />
                                            <div>
                                                <p className="font-semibold text-lg">{opinion.author}</p>
                                                <small className="text-stone-600">{opinion.product}</small>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>

                    </div>

                </div>
            </section>
        </>
    )
}

export default Opinions;
