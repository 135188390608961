import React, {useState} from "react";
import Cookies from 'universal-cookie';

const CookiesInfo = () => {
    const [cookie, setCookie] = useState(false);
    const CookiesManager = new Cookies();

    if (CookiesManager.get('acceptCookies') === 'true' && cookie !== true) {
        setCookie(true);
    }

    const setCookieBrowser = () => {
        CookiesManager.set('acceptCookies', 'true', {path: '/'});
        setCookie(true);
    }

    return (
        <>
            <div
            className= {"m-5 fixed lg:w-1/6 w-1/2 bottom-5 rounded-2xl border-4 border-orange-500 z-20 " + (cookie ? " hidden" : "")}
            style={{backgroundColor: "rgb(246,246,246)"}}
            >
                <div className="p-5">
                    <p>
                        Ten serwis wykorzystuje pliki cookies. Więcej informacji w
                        polityce prywatności.
                    </p>
                    <button className="text-orange-500 mt-2" onClick={() => setCookieBrowser()}>
                        Zamknij
                    </button>
                </div>
            </div>
        </>
    )
}

export default CookiesInfo;
